import { useEffect, useState } from "react";
import {
  LockOutlined,
  LogoutOutlined,
  BookOutlined,
  EditOutlined,
  InfoCircleOutlined,
  UserOutlined,
  MenuOutlined,
  CloseOutlined,
  BarChartOutlined,
  FileTextOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Dropdown, Avatar, Button, Flex, Divider } from "antd";
import type { MenuProps } from "antd";
import Logo from "../../assets/images/logo.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { mobileCheck } from "../../utils/responsive";
import { sessionStorageCache } from "../../cache";
import { routes, billingRoutes, accountRoutes } from "../../utils/routes";
import EllipsisTextWithSuffix from "./EllipsisTextWithSuffix";

const { Sider } = Layout;

const MIN_HEIGHT = 600;

const LeftNav: React.FC = () => {
  const [shouldScroll, setShouldScroll] = useState(false);

  const { user, isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();
  const isMobile = mobileCheck();
  const [collapsed, setCollapsed] = useState(isMobile);

  useEffect(() => {
    const checkHeight = () => {
      const windowHeight = window.innerHeight;
      setShouldScroll(windowHeight < MIN_HEIGHT);
    };

    checkHeight(); // Initial check
    window.addEventListener("resize", checkHeight); // Check on window resize

    return () => window.removeEventListener("resize", checkHeight); // Cleanup
  }, []);

  const toggleCollapsed = () => {
    isMobile && setCollapsed(!collapsed);
  };

  const userDropdownItems: MenuProps["items"] = [
    user?.given_name
      ? {
          key: "left-nav-user-name",
          label: <span>{user?.given_name}</span>,
        }
      : null,
    {
      key: "left-nav-user-email",
      label: <span>{user?.email}</span>,
    },
    {
      key: "left-nav-user-divider",
      type: "divider",
    },
    {
      key: "left-nav-log-out",
      icon: <LogoutOutlined />,
      label: "Log Out",
      onClick: () => {
        sessionStorageCache.clear();
        logout({ logoutParams: { returnTo: "https://www.voyageai.com" } });
      },
    },
  ];

  const userDropdown = (
    <Dropdown menu={{ items: userDropdownItems }} placement="top">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar size={16} icon={<UserOutlined />} style={{ marginRight: "10px" }} />
        <EllipsisTextWithSuffix
          copyable={false}
          text={isAuthenticated ? user?.given_name ?? user?.email : undefined}
          length={13}
        />
      </div>
    </Dropdown>
  );

  const items: MenuProps["items"] = [
    // Top part of the menu
    !isMobile
      ? {
          key: "logo",
          label: (
            <a href="https://www.voyageai.com">
              <img src={Logo} alt="Voyage AI Logo" style={{ marginTop: 20 }} />
            </a>
          ),
          disabled: true,
        }
      : null,
    {
      key: "left-nav-api-keys",
      icon: <LockOutlined style={{ fontSize: 15 }} />,
      label: "API Keys",
      onClick: () => {
        navigate(routes.apiKeyRoutes);
        toggleCollapsed();
      },
    },
    {
      key: "left-nav-usage",
      icon: <BarChartOutlined style={{ fontSize: 15 }} />,
      label: "Usage",
      onClick: () => {
        navigate(routes.usageRoutes);
        toggleCollapsed();
      },
    },
    {
      key: "left-nav-billing",
      icon: <DollarOutlined style={{ fontSize: 15 }} />,
      label: "Billing",
      onClick: () => {
        navigate(routes.billingRoutes + billingRoutes.overviewRoutes);
        toggleCollapsed();
      },
    },
    {
      key: "left-nav-account",
      icon: <UserOutlined style={{ fontSize: 15 }} />,
      label: "Account",
      children: [
        {
          key: "left-nav-account-profile",
          label: "Profile",
          onClick: () => {
            navigate(routes.accountRoutes + accountRoutes.profile);
            toggleCollapsed();
          },
        },
        {
          key: "left-nav-account-rate-limits",
          label: "Rate Limits",
          onClick: () => {
            navigate(routes.accountRoutes + accountRoutes.rateLimits);
            toggleCollapsed();
          },
        },
      ],
    },

    // Bottom part of the menu
    {
      key: "bottom",
      children: [
        {
          key: "left-nav-documentation",
          icon: <BookOutlined style={{ fontSize: 15 }} />,
          label: <a href="http://docs.voyageai.com/">Documentation</a>,
        },
        {
          key: "left-nav-blog",
          icon: <EditOutlined style={{ fontSize: 15 }} />,
          label: <a href="http://blog.voyageai.com/">Blog</a>,
        },
        {
          key: "left-nav-about",
          icon: <InfoCircleOutlined style={{ fontSize: 15 }} />,
          label: <a href="https://www.voyageai.com/about">About</a>,
        },
        {
          key: "left-nav-terms-of-service",
          icon: <FileTextOutlined style={{ fontSize: 15 }} />,
          label: "Term of Services",
          onClick: () => {
            navigate("/terms-of-service");
            toggleCollapsed();
          },
        },
        {
          key: "left-nav-divider",
          type: "divider",
        },
        {
          key: "left-nav-user-dropdown",
          label: userDropdown,
        },
      ],
      type: "group", // Use group to group the bottom items together
      style: { position: isMobile ? "relative" : shouldScroll ? "relative" : "absolute", bottom: 0, width: "100%" },
    },
  ];

  return (
    <>
      {isMobile && (
        <div style={{ position: "fixed", background: "#ffffff", zIndex: 100, width: "100%" }}>
          <Flex style={{ width: "100%", height: "70px" }} justify="space-between" align="center">
            <a href="https://www.voyageai.com">
              <img src={Logo} alt="Voyage AI Logo" style={{ marginLeft: 20 }} />
            </a>
            <Button onClick={toggleCollapsed} style={{ marginRight: 20 }}>
              {collapsed ? <MenuOutlined /> : <CloseOutlined />}
            </Button>
          </Flex>
          <Divider style={{ margin: 0 }} />
        </div>
      )}
      <Sider
        style={{
          background: "#ffffff",
          height: collapsed ? "auto" : "100vh",
          position: isMobile ? "fixed" : "sticky",
          left: isMobile ? "auto" : 0,
          right: isMobile ? 10 : "auto",
          top: isMobile ? "70px" : 0,
          zIndex: 100,
          width: collapsed ? "auto" : "100%",
          overflowY: shouldScroll || isMobile ? "scroll" : "inherit",
        }}
      >
        {!collapsed && (
          <Menu
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["left-nav-account"]}
            mode="inline"
            theme="light"
            style={{ height: "100vh", borderRight: 0 }}
            items={items}
            className="left-nav"
          />
        )}
      </Sider>
    </>
  );
};

export default LeftNav;
