import { FC } from "react";
import { Typography, Result } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { MailOutlined, InfoCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

const LoginError: FC<{ description: string }> = ({ description }) => {
  const { logout } = useAuth0();

  const isEmailUnverified = description === "Please verify your email before logging in.";
  return (
    <Result
      title={isEmailUnverified ? "Please verify your email address" : description}
      icon={isEmailUnverified ? <MailOutlined /> : <InfoCircleOutlined />}
      extra={
        isEmailUnverified ? (
          <>
            <Title level={5}>
              An email has been sent to you email address, Please verify your email and come back later.
            </Title>
            <Title level={5}>
              Click{" "}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  logout({ logoutParams: { returnTo: "https://www.voyageai.com" } });
                }}
              >
                here
              </a>{" "}
              to login again.
            </Title>
          </>
        ) : null
      }
    />
  );
};

export default LoginError;
