import { FC } from "react";
import dayjs, { Dayjs } from "dayjs";
import { CostsData } from "../../types/billing";
import { ChartData, CostsAggregatedData } from "../../types/charts";
import { mobileCheck } from "../../utils/responsive";
import ModelChart from "../charts/ModelChart";
import { Row, Col } from "antd";
import { TOTAL_COSTS_MODEL_NAME, COSTS_SORT_KEY } from "../../utils/chartsConstants";

const generateDaysArrayForCurrentMonth = (selectedMonth: Dayjs): number[] => {
  const startOfMonth = dayjs(selectedMonth).startOf("month");
  const endOfMonth = dayjs(selectedMonth).endOf("month");
  const daysInMonth = endOfMonth.diff(startOfMonth, "day") + 1;

  return Array.from({ length: daysInMonth }, (_, index) => index + 1);
};

const aggregateDataByModel = (
  items: CostsData[],
  modelName: CostsData["model_name"],
  selectedMonth: Dayjs,
  isAccumulative: boolean,
): { costsData: ChartData; totalCosts: number } => {
  const daysInSelectedMonth = generateDaysArrayForCurrentMonth(selectedMonth);
  const initData: CostsAggregatedData = daysInSelectedMonth.reduce((acc, day) => ({ ...acc, [day]: { cost: 0 } }), {});

  let totalCosts = 0;

  items.forEach((item) => {
    if (item.model_name === modelName || modelName === TOTAL_COSTS_MODEL_NAME) {
      const date = dayjs(item.timestamp);
      const dateString = date.format("D");
      totalCosts += item.cost;
      if (initData[dateString]) {
        initData[dateString].cost += item.cost;
      }
    }
  });

  if (isAccumulative) {
    // if show accumulative data, loop the objects again, add up each day
    let costsAccumulation = 0;
    Object.keys(initData).forEach((item) => {
      costsAccumulation += initData[item].cost;
      initData[item] = {
        cost: costsAccumulation,
      };
    });
  }

  const labels = Object.keys(initData).map((date) => `${date} ${selectedMonth.format("MMM")}`);
  const costsData = Object.values(initData).map((data) => data.cost);

  return {
    totalCosts,
    costsData: {
      labels,
      datasets: [
        {
          data: costsData,
          fill: true,
          tension: 0.5,
          backgroundColor: "rgb(75, 192, 192)",
          borderColor: "rgba(75, 192, 192, 0.2)",
        },
      ],
    },
  };
};

type Props = {
  costsData: CostsData[];
  uniqueModelNames: string[];
  selectedMonth: Dayjs;
  selectedModel: string;
  isAccumulative: boolean;
};

const CostsChart: FC<Props> = ({ costsData, selectedMonth, uniqueModelNames, selectedModel, isAccumulative }) => {
  const isMobile = mobileCheck();
  return (
    <>
      <Row justify="space-between">
        {uniqueModelNames
          .map((modelName) => {
            return {
              modelName: modelName,
              aggregateData: aggregateDataByModel(costsData, modelName, selectedMonth, isAccumulative),
            };
          })
          .filter((data) => {
            // if no model is selected display everything
            if (!selectedModel) {
              return true;
            } else {
              return data.modelName === selectedModel;
            }
          })
          .sort((a, b) => {
            return b.aggregateData[COSTS_SORT_KEY] - a.aggregateData[COSTS_SORT_KEY];
          })
          .map((data) => {
            const { costsData, totalCosts } = data.aggregateData;
            const modelName = data.modelName;
            return (
              <Col key={modelName} span={isMobile ? 24 : 11}>
                <ModelChart chartData={costsData} modelName={modelName} chartType="$" total={totalCosts} />
              </Col>
            );
          })}
      </Row>
    </>
  );
};

export default CostsChart;
