import { FC } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ApiKey from "../pages/ApiKey";
import Usage from "../pages/Usage";
import BillingRoute from "./BillingRoute";
import AccountRoute from "./AccountRoute";
import { Layout, Flex } from "antd";
import LeftNav from "../components/navigation/LeftNav";
import PageHeader from "../components/navigation/PageHeader";
import { routes, billingRoutes, accountRoutes } from "../utils/routes";
import { mobileCheck } from "../utils/responsive";
// import gates from "../utils/gates";
import TermsOfService from "../pages/TermsOfService";

const RootRoute: FC = () => {
  const isMobile = mobileCheck();

  return (
    <Flex vertical={isMobile}>
      <LeftNav />
      <Layout>
        <Layout.Content
          style={{
            background: "#ffffff",
            padding: "10px 24px",
            marginTop: isMobile ? "70px" : "0",
            overflow: "initial",
            paddingBottom: 40,
          }}
        >
          <PageHeader />
          <Routes>
            <Route path={routes.default} element={<ApiKey />} />
            <Route path={routes.apiKeyRoutes} element={<ApiKey />} />
            <Route path={routes.usageRoutes} element={<Usage />} />
            <Route path={`${routes.billingRoutes}/*`} element={<BillingRoute />} />
            <Route
              path="/billing"
              element={<Navigate replace to={`${routes.billingRoutes}${billingRoutes.overviewRoutes}`} />}
            />
            <Route path={`${routes.accountRoutes}/*`} element={<AccountRoute />} />
            <Route
              path="/account"
              element={<Navigate replace to={`${routes.accountRoutes}${accountRoutes.profile}`} />}
            />
            <Route path={routes.termsOfServiceRoutes} element={<TermsOfService />} />
          </Routes>
        </Layout.Content>
      </Layout>
    </Flex>
  );
};

export default RootRoute;
