import { useState, useEffect } from "react";
import { Col, Row, Typography, Button } from "antd";
import AddPaymentModal from "../../components/billing/AddPaymentModal";
import PaymentMethodsList from "../../components/billing/PaymentMethodsList";
import LoadingPage from "../Loading";
import usePaymentMethods from "../../hooks/usePaymentMethods";

const { Title } = Typography;
const BillingPaymentMethods = () => {
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false);
  const { paymentMethods, loading, fetchPaymentMethods } = usePaymentMethods();

  useEffect(() => {
    fetchPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Row>
      <Col span={24}>
        {!paymentMethods?.length ? (
          <Title level={5} style={{ marginTop: 15 }}>
            No payment method has been added
          </Title>
        ) : (
          <PaymentMethodsList fetchPaymentMethods={fetchPaymentMethods} paymentMethods={paymentMethods} />
        )}

        <Button style={{ marginTop: 15 }} onClick={() => setAddPaymentModalOpen(true)}>
          Add payment method
        </Button>

        <AddPaymentModal
          fetchPaymentMethods={fetchPaymentMethods}
          open={addPaymentModalOpen}
          setOpen={setAddPaymentModalOpen}
        />
      </Col>
    </Row>
  );
};

export default BillingPaymentMethods;
