import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axiosClient from "./axios";
import { notification } from "antd";

const useAxiosInterceptor = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const interceptor = axiosClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        // Check if error is due to expired token
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            // Attempt to get a new access token
            const token = process.env.REACT_APP_BEARER_TOKEN ?? (await getAccessTokenSilently());
            // Update the Authorization header with the new token
            axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            // Retry the original request with the new token
            return axiosClient(originalRequest);
          } catch (tokenRefreshError) {
            // If token refresh fails, redirect to login page
            await loginWithRedirect();
            return Promise.reject(tokenRefreshError);
          }
        } else {
          const message = error.response?.data?.detail || error.message;
          notification.error({
            message: "Error",
            description: message,
            duration: 5,
          });
        }

        return Promise.reject(error);
      },
    );

    return () => {
      axiosClient.interceptors.response.eject(interceptor);
    };
  }, [getAccessTokenSilently, loginWithRedirect]);
};

export default useAxiosInterceptor;
