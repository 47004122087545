import { useState, useCallback } from "react";
import { getPaymentMethods } from "../actions/billingActions";
import { PaymentMethod } from "../types/billing";
import { setDefaultPaymentMethods } from "../actions/billingActions";

const usePaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchPaymentMethods = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getPaymentMethods();
      setPaymentMethods(res.data);

      // when fetching payment methods, if there is only 1 card and the card is newly added
      // which means is_default is not true, set it as the default payment method then refetch
      // payment methods data again.
      if (res.data.length === 1 && !res.data[0].is_default) {
        setDefaultPaymentMethods(res.data[0].id)
          .then(async () => {
            await fetchPaymentMethods();
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  return {
    paymentMethods,
    loading,
    fetchPaymentMethods,
  };
};

export default usePaymentMethods;
