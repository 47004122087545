import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { accountRoutes } from "../utils/routes";
import Profile from "../pages/account/Profile";
import RateLimits from "../pages/account/RateLimits";

const AccountRoute: FC = () => {
  return (
    <Routes>
      <Route path={accountRoutes.profile} element={<Profile />} />
      <Route path={accountRoutes.rateLimits} element={<RateLimits />} />
    </Routes>
  );
};

export default AccountRoute;
