import compact from "lodash/compact";

type TKey = string | string[];

type CacheItem<Value = any> = {
  value?: Value;
  expires?: number | boolean;
};

const buildKey = (key: TKey): string => {
  if (Array.isArray(key)) {
    key = compact(key).join(":");
  }

  return "" + key;
};

const tryParse = <Value>(item: string): CacheItem<Value> => {
  try {
    return JSON.parse(item);
  } catch (err) {
    return {};
  }
};

export const get = (storage: Storage) => {
  return <Value = any>(key: TKey, isUnFormatted = false) => {
    const name = buildKey(key);

    // Get object from storage
    let storageItem: string | null;
    try {
      storageItem = storage.getItem(name);
    } catch (err) {
      console.warn(err);
      return;
    }

    if (storageItem === null) {
      return;
    }

    // Access unformatted item from storage if needed
    if (isUnFormatted) {
      return storageItem;
    }

    const parsed = tryParse<Value>(storageItem);

    // delete key if it's format unfriendly
    if (!parsed || !parsed.hasOwnProperty("value") || !parsed.hasOwnProperty("expires")) {
      console.warn(`Found incompatible format for item: ${name}: ${parsed}`);
      remove(storage)(key);
      return;
    }

    const { value, expires } = parsed;
    const expired = expires && new Date().getTime() > expires;

    if (expired) {
      remove(storage)(key);
    }

    if (expired || value === undefined) {
      return;
    }

    return value;
  };
};

export const set = (storage: Storage) => {
  return (key: TKey, value: any, expiresSecondsFromNow?: number, doNotFormat = false) => {
    const name = buildKey(key);

    // Sometimes setting an item blows up because a user has a full local storage
    // Writing to it would exceed the quota and make things blow up
    // The try handles that case
    try {
      storage.setItem(
        name,
        doNotFormat
          ? value
          : JSON.stringify({
              value,
              expires: expiresSecondsFromNow === undefined ? false : new Date().getTime() + expiresSecondsFromNow,
            }),
      );
    } catch (err) {
      console.warn(err);
    }
  };
};

export const remove = (storage: Storage) => {
  return (key: TKey) => {
    const name = buildKey(key);
    try {
      storage.removeItem(name);
    } catch (err) {
      console.warn(err);
    }
  };
};

export const has = (storage: Storage) => {
  return (key: TKey) => {
    return get(storage)(key) !== undefined;
  };
};

export const clear = (storage: Storage) => {
  return () => {
    try {
      storage.clear();
    } catch (err) {
      console.warn(err);
    }
  };
};
