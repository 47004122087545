export const TERMS = {
  title1: "Voyage AI Innovations, Inc.",
  title2: "TERMS OF SERVICE",
  subtitle: "Last Updated: February  20, 2024",
  description: [
    "The following terms and conditions (the “Agreement”) govern all use of the Voyage AI’s website (located at www.voyageai.com – “Website”) and products and services (collectively, the “Service”).  The Service is owned and operated by Voyage AI Innovations, Inc. (“Voyage AI”, “us”, “we”, or “our”).  The Service is offered subject to your acceptance without modification of all the terms and conditions contained herein.  BY USING OR ACCESSING ANY PART OF THE SERVICE, YOU AGREE TO ALL OF THE TERMS AND CONDITIONS CONTAINED HEREIN; IF YOU DO NOT AGREE, DO NOT USE THE SERVICE.",
    "Voyage AI reserves the right, at its sole discretion, to modify or replace any of the terms or conditions of this Agreement at any time. If we have your email address, we will notify you via email. In addition, we will update the “Last Updated” date above. ",
    "To the extent you have an executed agreement with Voyage AI with respect to the subject matter here, such separate agreement will control.",
  ],
  contents: [
    {
      title: "1. ARBITRATION AND CLASS ACTION WAIVER",
      texts: [
        "WE HAVE CERTAIN REQUIREMENTS THAT YOU AGREE TO ARBITRATE DISPUTES YOU MAY HAVE WITH US – AND WAIVE RIGHTS TO BRING CLASS ACTION LAWSUITS. PLEASE SEE SECTION 17 (“DISPUTE RESOLUTION”) BELOW FOR MORE INFORMATION. BY ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT AND HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES OF ENTERING THIS AGREEMENT.",
      ],
    },
    {
      title: "2. User Account; Access",
      texts: [
        "In order to use the Service you may be required to create a Service account (“Account”). You represent and warrant that: (a) all required registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information. You will not share your Account login credentials (such as your passwords) with any third party. You must immediately notify us of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security with respect to your Account. ",
        "Voyage AI may change, suspend or discontinue any aspect of the Service at any time.  Voyage AI may also impose limits on certain features and services or restrict your access to parts or all of the Service without notice or liability.",
        "The Service is available only to individuals who are at least 18 years old.  You represent and warrant that if you are an individual, you are at least 18 years old. You also certify that you take full responsibility for the selection and use of the Service. This Agreement is void where prohibited by law, and the right to access the Service is revoked in such jurisdictions.",
        "You shall be responsible for obtaining and maintaining any equipment or ancillary services needed to connect to and access the Service, including, without limitation, modems, hardware, software, and long distance or local telephone service.  You shall be responsible for ensuring that such equipment or ancillary services are compatible with the Service.",
      ],
    },
    {
      title: "3. Content and Models",
      texts: [
        "You retain ownership of all data and other information you provide to Voyage AI or otherwise load into the Service (including, for clarity, data provided for ‘fine tuning’ artificial intelligence models) (“Customer Content”).",
        "Unless you ‘opt out’ as described below, you grant Voyage AI (and its successors and assigns) a worldwide, irrevocable, perpetual, royalty-free, fully paid-up, right and license to use, copy, reproduce, distribute, prepare derivative works of, display and perform the Customer Content: (i) to maintain and provide you with the Service, (ii) to exercise its rights and obligations, and otherwise enforce, this Agreement, and (iii) to train, improve, and otherwise further develop the Service (such as by training the artificial intelligence models we use). Notwithstanding the foregoing, other than to our sub-processors and subcontractor acting on our behalf, we will not disclose your Customer Content to third parties other than in an aggregate and anonymized manner that does not identify you.",
        "You may opt out of our use rights in Section 3(iii) above via the opt-out functionality on the Website. If you choose to opt out, it will apply only to Customer Content you submit after the time at which you out opt. If you opt out, your Customer Content provided after such opt out will be immediately deleted by Voyage AI after it is processed for you. For clarity, any data provided prior to your opt out may continue to be subject to Section 3(iii). If you opt out, any credits or tokens for free usage of the Service may be automatically void (as determined by Voyage AI in its sole discretion).",
        "In the event you use the Service to create any Fine-Tuned Models, Voyage AI will own such models (unless otherwise agreed to by the parties in writing), but Voyage AI will not sell or otherwise share such models with third parties (but, for clarity, may share them as appropriate for Sections 3(i) and (ii) above). “Fine-Tuned  Model” means an artificial intelligence neural network model that is based on parameters that are trained using data submitted by you in order to customize the model for Customer Content.",
      ],
    },
    {
      title: "4. Platform Data",
      texts: [
        "In the course of providing the Service, Voyage AI may collect statistical data and performance information, analytics, meta-data or similar information, generated through instrumentation and logging systems, regarding the operation of the Service, including Customer’s use of the Service (the “Platform Data”). Nothing in this Agreement shall restrict Voyage AI’s right to collect Platform Data or to use it for any internal business purpose, provided however, that (i) Platform Data will not include any Customer Content, and (ii) Voyage AI will not disclose Platform Data to any third party in a manner that allows such third party to identify Customer, other than Voyage AI’s employees, agents or service providers who are subject to obligations of confidentiality with respect to such Platform Data.",
      ],
    },
    {
      title: "5. Fees",
      texts: [
        'Fees for purchasing the Service (or any aspects thereof) will be as set forth by Voyage AI on the Website (collectively, "Fees"). All amounts are due in U.S. Dollars. You acknowledge and agree that Voyage AI may use a third-party payment processor to help facilitate payments hereunder. To the extent payment is with a credit card – you warrant you are the owner or authorized user of the applicable card – and that we, and our payment processor, may charge such card. All information you submit in connection with your purchase (such as your email, address, etc.) must be true, accurate and up-to-date.',
        "You are responsible for all taxes associated with your purchase (other than taxes based on Voyage AI’s income).",
        "If your purchase is subject to recurring charges, then you consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge. Your account will be charged for renewal within 24-hours prior to the end of the current period. To avoid being charged for an upcoming renewal period, you must cancel your account at least 24-hours before the end of the current subscription period. If you cancel after such period, no fees already charged will be refunded.",
        "We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment. We also reserve the right to refuse any order placed through the Website.",
        "Except as otherwise set forth by Voyage AI on the Website – all Fees are non-cancellable and non-refundable. ",
      ],
    },
    {
      title: "6. Use of the Service; No Support",
      texts: [
        "You represent and warrant (i) your use of the Service will comply with all laws and regulations (including with respect to privacy), (ii) no Customer Content infringes or otherwise violates any third party intellectual property rights or any laws or regulations (including, without limitation, as related to obscenity, defamation and privacy), (iii) if you use the Service on behalf of any third party, you have all necessary authorizations, and (iv) your use of the Service will not conflict with any obligations you have to any third party.  ",
        "Voyage AI has no obligation to provide any support hereunder.",
      ],
    },
    {
      title: "7. Other Restrictions",
      texts: [
        "You shall not submit any content or information to the Service that is false, misleading or inaccurate.",
        "You shall not use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Service, or in any way reproduce or circumvent the navigational structure or presentation of the Service, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Service.  Voyage AI reserves the right to bar any such activity.",
        "You shall not attempt to gain unauthorized access to any portion or feature of the Service, or any other systems or networks connected to the Service or to any Voyage AI server, or to any of the services offered on or through the Service, by hacking, password “mining”, or any other illegitimate means.",
        "You shall not probe, scan or test the vulnerability of the Service or any network connected to the Service, nor breach the security or authentication measures on the Service or any network connected to the Service.  You shall not reverse look-up, trace or seek to trace any information on any other user of or visitor to the Service, or any other customer of Voyage AI, including any Voyage AI account not owned by you, to its source, or exploit the Service or any service or information made available or offered by or through the Service, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by the Service.",
        "You shall not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Service or Voyage AI’s systems or networks, or any systems or networks connected to the Service or to Voyage AI.",
        "You shall not use any device, software or routine to interfere or attempt to interfere with the proper working of the Service or any transaction being conducted on the Service, or with any other person’s use of the Service.",
        "You shall not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to Voyage AI on or through the Service.  You shall not, in connection with the Service, pretend (e.g. through impersonation) that you are any other person.",
        "You shall not use the Service for any purpose that is unlawful or prohibited by this Agreement, or which infringes the rights of Voyage AI or others.",
      ],
    },
    {
      title: "8. Feedback",
      texts: [
        'If you provide ideas, suggestions, or other feedback to Voyage AI in connection with the Service (such as suggestions for improvements to the Service) ("Feedback"), you grant Voyage AI (and our successors and assigns) a perpetual, irrevocable, worldwide, sublicensable, transferrable, royalty-free, fully paid-up right and license to use, reproduce, display, perform, distribute, sell, and otherwise fully exploit all Feedback.',
      ],
    },
    {
      title: "9. Third-Party Services",
      texts: [
        "The Service may permit you to link to the Third-Party Offerings and other third-party websites or applications or services, and other websites or applications or services may contain links to the Service.  Such third-party offerings, websites, and services are not under Voyage AI’s control, and you acknowledge that Voyage AI is not responsible or liable for the content, functions, accuracy, legality, appropriateness or any other aspect of them.  The inclusion of any such link does not imply endorsement by Voyage AI.  You further acknowledge and agree that Voyage AI shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, goods, information, or services available on or through any such offerings, websites, or services.",
      ],
    },
    {
      title: "10. Indemnification",
      texts: [
        "You shall defend, indemnify, and hold harmless Voyage AI and each of our employees, contractors, directors, providers, suppliers and representatives from all liabilities, damages, losses, settlements, claims, actions, suits, demands, proceedings, costs and expenses, including attorneys' fees, that arise from or in connection with: (i) your use or misuse of the Service; (ii) your Customer Content, (iii) your access to any part of the Service, (iv) your negligence, misconduct, or other fault, (v) any dispute you may be involved in with another Service user, or (vi) your violation of this Agreement.",
      ],
    },
    {
      title: "11. Warranty Disclaimer",
      texts: [
        'THE SERVICE IS PROVIDED ON AN "AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. VOYAGE AI MAKES NO WARRANTY THAT (I) THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (II) THE RESULTS OF USING THE SERVICE WILL MEET YOUR REQUIREMENTS. ',
        "CERTAIN ASPECTS OF THE SERVICE MAY INVOLVE ARTIFICIAL INTELLIGENCE (“GENERATIVE AI”). YOU ACKNOWLEDGE THAT ARTIFICIAL INTELLIGENCE SYSTEMS ARE A RAPIDLY EVOLVING FIELD. WHILE VOYAGE AI IS ALWAYS WORKING TO IMPROVE THE SERVICE, DUE TO THE PROBABILISTIC NATURE OF MACHINE LEARNING, THE SERVICE MAY PROVIDE INACCURATE OUTPUT OR OTHERWISE NOT ALWAYS PRODUCE THE INTENDED RESULTS. AS SUCH, YOU ACKNOWLEDGE THAT NO WARRANTIES ARE MADE BY VOYAGE AI WITH RESPECT TO (AND VOYAGE AI WILL HAVE NO LIABILITY WITH RESPECT TO) THE OUTPUT OR OTHER RESULTS (OR YOUR USE THEREOF) OF ANY GENERATIVE AI. ",
        "IN ADDITION, CUSTOMER AGREES IT WILL NOT USE ANY GENERATIVE AI FEATURES OR FUNCTIONS TO POWER, OR MAKE ANY DECISIONS RELATED TO, ANY PRODUCTS OR SERVICES THAT MAY INVOLVE PERSONAL INJURY OR ILLEGALITY (SUCH AS MEDICAL DEVICES, NUCLEAR PLANT OPERATIONS, WEAPONS APPLICATIONS, ETC.).",
        "THE FOREGOING DISCLAIMERS SHALL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.",
      ],
    },
    {
      title: "12. Limitation of Liability",
      texts: [
        "IN NO EVENT SHALL VOYAGE AI, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, OR PROVIDERS BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL THEORY WITH RESPECT TO THE SERVICE: (I) FOR ANY LOST PROFITS OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, EVEN IF FORESEEABLE, (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), (III) FOR ANY ERRORS OR OMISSIONS IN THE SERVICE, OR (IV) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) THE AMOUNTS (UNITED STATES DOLLARS – USD) PAID BY YOU TO VOYAGE AI DURING THE IMMEDIATELY PRECEDING THREE (3) MONTH PERIOD (BUT, IF NO AMOUNTS HAVE BEEN PAID, SUCH CAP WILL BE USD$5.00).  ",
        "WITHOUT LIMITING THE FOREGOING, YOU ACKNOWLEDGE AND AGREE THAT VOYAGE IS NOT LIABLE FOR ANY OUTPUT OR RESULTS FROM ANY GENERATIVE AI (OR ANY USE OR RELIANCE ON ANY SUCH OUTPUT OR RESULTS).",
        "IN ADDITION, ALL USERS ACKNOWLEDGE AND AGREE THAT VOYAGE AI SHALL NOT BE LIABLE FOR ANY MATTERS BEYOND VOYAGE AI’S REASONABLE CONTROL, INCLUDING, WITHOUT LIMITATION, MECHANICAL, ELECTRONIC, OR COMMUNICATIONS FAILURE OR DEGRADATION.",
        "THE FOREGOING LIMITATIONS SHALL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.",
      ],
    },
    {
      title: "13. Term and Termination",
      texts: [
        "Voyage AI may terminate your access to all or any part of the Service at any time, with or without notice, if you breach this Agreement, fail to comply with any laws or regulations, or we deem you pose a risk to our systems, networks, or other users. Voyage AI may also terminate your access to all or any part of the Service at any time, with or without cause, on thirty (30) days email notice. You can terminate this Agreement by permanently ceasing to use the Service. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, warranty disclaimers, limitations of liability, and outstanding payment obligations. ",
      ],
    },
    {
      title: "14. Export and Trade Controls",
      texts: [
        "You agree not to import, export, re-export, or transfer, directly or indirectly, any part of the Service or any information provided on or through the Service except in full compliance with all United States, foreign and other applicable laws and regulations.",
      ],
    },
    {
      title: "15. Electronic Communications",
      texts: [
        "When you use the Service or send emails to Voyage AI, you are communicating with Voyage AI electronically.  You hereby consent to receive communications from us electronically.  You agree that all agreements, notices, disclosures and other communications that Voyage AI provides to you electronically satisfy any legal requirement that such communications be in writing.",
      ],
    },
    {
      title: "16. Notices",
      texts: [
        "All legal notices to Voyage AI must be sent via email to: legal@voyageai.com (the subject heading must include: Legal).  If you have general questions regarding this Agreement or the Service, you may also email us at contact@voyageai.com .",
        "Voyage AI may provide notices to you via email and to any other address you provide.",
      ],
    },
    {
      title: "17. Dispute Resolution",
      texts: [
        {
          title: "Let's Try To Work It Out.",
          text: " Ideally, if you have any concerns or complaints against Voyage AI, we would like to resolve the issue without resorting to formal court or arbitration proceedings. Therefore, before filing a claim against Voyage AI, you agree to try to resolve the dispute informally by contacting us as set forth in Section 16 above. Voyage AI will attempt to resolve the dispute informally (and will contact you via email). If a dispute is not resolved within thirty (30) days of submission, you may bring a formal proceeding.",
        },
        {
          title: "Arbitration.",
          text: " Any disputes that are not settled informally as contemplated above shall be settled by binding arbitration in accordance with the rules and procedures (“Rules”) of the American Arbitration Association (“AAA”). The Rules will be AAA’s Consumer Arbitration Rules if available. Arbitration shall be conducted by one (1) arbitrator selected in accordance with the Rules.  In the event any telephonic or other streamlined procedures are available – they will be used. The written decision of the arbitrator shall be final and binding on the parties and enforceable in any court.   The arbitration proceeding shall take place in San Francisco, California using the English language.  Notwithstanding the foregoing, either party may bring claims for equitable or injunctive relief before a court (see the “Going to Court” section below) at any time. ",
        },
        {
          title: "Process.",
          text: " A form for initiating arbitration proceedings is available on the AAA's site at www.adr.org. In addition to filing this form with the Rules, the party initiating the arbitration must email a copy of the completed form to the opposing party. You may send a copy to Voyage AI at: legal@voyageai.com (the subject heading must Include: Arbitration). In the event we initiate arbitration against you, we will send a copy of the completed form to the email address or physical address we have on file.",
        },
        {
          title: "Evidence.",
          text: " If your claim does not exceed $10,000, then the arbitration will be conducted solely on the basis of documents we and you submit to the arbitrator, unless you request a hearing or the arbitrator determines that a hearing is necessary. If your claim exceeds $10,000, your right to a hearing will be determined by the Rules. Subject to the Rules, the arbitrator will have the discretion to direct a reasonable exchange of information by the parties, consistent with the expedited nature of the arbitration.",
        },
        {
          title: "Fees.",
          text: " The parties’ will each bear their own costs and expenses associated with arbitration – and responsibility to pay any AAA filing, administrative and arbitrator fees will be solely as set forth in the AAA Rules. ",
        },
        {
          title: "No Class Actions.",
          text: " You may only resolve disputes with Voyage AI on an individual basis, and may not bring a claim as a plaintiff or a class member in a class, consolidated, or representative action. Class arbitrations, class actions, private attorney general actions, and consolidation with other arbitrations are not allowed. ",
        },
        {
          title: "Modifications.",
          text: " Notwithstanding any provision in this Agreement to the contrary, you and we agree that if we make any amendment to the foregoing arbitration terms or conditions (other than an amendment to any notice address or site link) in the future, such amendment shall not apply to any claim that was filed in a legal proceeding against Voyage AI prior to the effective date of the amendment. In addition, you shall have the reject any such amendment by notifying Voyage AI in writing within thirty (30) days of the date that you are first notified of the amendment (according to the procedures set forth above in this Section 17). In the event you so reject an amendment, the amendment will not apply to you – but this Section 17 will otherwise remain in fully force and effect (according to the pre-amendment version you had already agreed to).",
        },
        {
          title: "Going to Court.",
          text: " Subject to the arbitration provision above, you agree that the exclusive jurisdiction and venue for all disputes arising in connection with this Agreement shall be in the state and Federal courts located in San Francisco, California.  You hereby submit to such jurisdiction and venue.",
        },
        "THE TERMS AND CONDITIONS IN THIS SECTION 17 WILL NOT APPLY TO THE EXTENT PROHIBITED OR OTHERWISE.",
      ],
    },
    {
      title: "18. Electronic Communications, Transactions, and Signature",
      texts: [
        "Visiting the Website, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.",
      ],
    },
    {
      title: "19. Website Content",
      texts: [
        "Unless otherwise indicated, the Website is Voyage AI’s proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, international copyright laws, and international conventions. The Content and the Marks are provided on the Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.",
        "Provided that you are eligible to use the Website, you are granted a limited license to access and use the Website and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Website, the Content and the Marks.",
      ],
    },
    {
      title: "20. General",
      texts: [
        "The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. If any provision of this Agreement is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement shall otherwise remain in full force and effect and enforceable. This Agreement is not assignable, transferable or sublicensable by you except with Voyage AI’s prior written consent.  Voyage AI may transfer, assign or delegate this Agreement and its rights and obligations without consent.  This Agreement shall be governed by and construed in accordance with the laws of the state of California, as if made within California between two residents thereof.  Both parties agree that this Agreement is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of this Agreement, and that all modifications must be in a writing signed by both parties, except as otherwise provided herein. No agency, partnership, joint venture, or employment is created as a result of this Agreement and you do not have any authority of any kind to bind Voyage AI in any respect whatsoever.",
      ],
    },
  ],
};
