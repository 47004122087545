import { useState, useEffect } from "react";
import { Col, Row, Typography } from "antd";
import { getInvoices } from "../../actions/billingActions";
import { Invoice } from "../../types/billing";
import LoadingPage from "../Loading";
import InvoiceTable from "../../components/billing/InvoiceTable";

const { Title } = Typography;

const BillingHistory = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  useEffect(() => {
    setLoading(true);
    getInvoices()
      .then((res) => {
        setInvoices(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Row>
      <Col span={24}>
        <Title level={5} style={{ marginTop: 15 }}>
          Invoices within the past 12 months
        </Title>
        <InvoiceTable invoices={invoices} loading={loading} />
      </Col>
    </Row>
  );
};

export default BillingHistory;
