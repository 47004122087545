import { FC, useState } from "react";
import { Button, Modal, notification } from "antd";
import { deleteApiKey } from "../../actions/apiKeyActions";

type Props = {
  id: string;
  name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  fetchApiKeys: Function;
};

const ApiKeyDeleteModal: FC<Props> = ({ id, name, open, setOpen, fetchApiKeys }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setConfirmLoading(true);
      deleteApiKey(id)
        .then(async () => {
          await fetchApiKeys();
          notification.success({
            message: "Success",
            description: "API key deleted successfully.",
            duration: 5,
          });
          setOpen(false);
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    } catch (e) {
      setConfirmLoading(false);
      setOpen(false);
      throw e;
    }
  };
  return (
    <Modal
      key="delete-new-key-modal"
      title="Confirm Delete"
      open={open}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button type="primary" loading={confirmLoading} onClick={handleSubmit}>
          Delete
        </Button>,
      ]}
    >
      <div
        style={{
          margin: "20px 0",
        }}
      >
        Are you sure you want to delete key <b>{name}</b>
      </div>
    </Modal>
  );
};

export default ApiKeyDeleteModal;
