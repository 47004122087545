import { FC } from "react";
import { Col, Row, Table, Flex, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { RateLimitsData, RateLimitsTableData } from "../../types/account";
import { mobileCheck } from "../../utils/responsive";
import { formatNumber } from "../../utils/chartsConstants";

type Props = {
  rateLimits: RateLimitsData[];
  loading: boolean;
};

const RateLimitsTable: FC<Props> = ({ rateLimits, loading }) => {
  const isMobile = mobileCheck();

  const columns: ColumnsType<RateLimitsTableData> = [
    {
      title: "Models",
      dataIndex: "endpoint",
      key: "endpoint",
      width: isMobile ? undefined : 400,
      render: (text, _, index) => {
        return (
          <Flex wrap="wrap" style={{ width: isMobile ? "auto" : 400 }}>
            {index === 0 ? <div style={{ width: "100%", marginLeft: 5 }}>Series 1&2 embedding models:</div> : null}
            {text.split(",").map((model: string) => {
              return <Tag style={{ display: "block", margin: 5 }}>{model}</Tag>;
            })}
          </Flex>
        );
      },
    },
    {
      title: "TPM",
      dataIndex: "TPM",
      key: "TPM",
      render: (text) => formatNumber(text),
    },
    {
      title: "RPM",
      dataIndex: "RPM",
      key: "RPM",
      render: (text) => formatNumber(text),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={rateLimits.map((rateLimit, index) => ({
              ...rateLimit,
              key: `rate-limit-${index}`,
            }))}
            pagination={false}
            size={isMobile ? "small" : "large"}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default RateLimitsTable;
