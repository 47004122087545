import axios from "../utils/axios";
const activityUrl = "activity";

export const getActivityByDate = (startDate: string, endDate: string) =>
  axios.get(activityUrl, {
    params: {
      "start-date": startDate,
      "end-date": endDate,
    },
  });
