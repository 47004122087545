import { FC } from "react";
import { Typography } from "antd";
const { Text } = Typography;

type EllipsisTextWithSuffixProps = {
  text: string | undefined;
  length: number;
  copyable: boolean;
};

const EllipsisTextWithSuffix: FC<EllipsisTextWithSuffixProps> = ({ copyable, text, length }) => {
  if (!text) {
    return null;
  }
  const displayText = text.length > length ? `${text.substring(0, length)}...` : text;

  return <Text copyable={copyable && { text }}>{displayText}</Text>;
};

export default EllipsisTextWithSuffix;
