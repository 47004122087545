import { useState, useEffect, useCallback } from "react";
import { Row, Typography, Switch, Flex, Card, Divider, Tooltip } from "antd";
import { TERMS } from "../utils/termsOfService";
import { getOptOutStatus, updateOptOutStatus } from "../actions/termsOfServiceAction";
import useBillingOverview from "../hooks/useBillingOverview";

const { Text, Title } = Typography;
const TermsOfService = () => {
  const [loading, setLoading] = useState(false);
  const [optOutStatus, setOptOutStatus] = useState<boolean>(false);
  const { loading: loadingOverview, fetchOverview, isFreeUser } = useBillingOverview();

  const fetOptOutStatus = useCallback(async () => {
    setLoading(true);
    getOptOutStatus()
      .then((res) => {
        setOptOutStatus(res.data.tos_opt_out);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchOverview();
    fetOptOutStatus();
  }, [fetchOverview, fetOptOutStatus]);

  const onChange = (status: boolean) => {
    setLoading(true);
    updateOptOutStatus(!status)
      .then((res) => {
        setOptOutStatus(res.data.tos_opt_out);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <Row>
      <Flex vertical align="center">
        <Card>
          <Flex justify="center">
            <Title level={4} style={{ marginTop: 0 }}>
              {TERMS.title1}
            </Title>
          </Flex>
          <Flex justify="center">
            <Title level={4} style={{ marginTop: 0 }}>
              {TERMS.title2}
            </Title>
          </Flex>
          <Title level={5}>{TERMS.subtitle}</Title>

          {TERMS.description.map((des) => {
            return <Text>{des}</Text>;
          })}
          {TERMS.contents.map((content) => {
            return (
              <>
                <Title level={5}>{content.title}</Title>
                {content.texts.map((text) => {
                  return typeof text === "string" ? (
                    <Text style={{ marginTop: 10, display: "block" }}>{text}</Text>
                  ) : (
                    <Text style={{ marginTop: 10, display: "block" }}>
                      <Text underline italic>
                        {text.title}
                      </Text>
                      <Text>{text.text}</Text>
                    </Text>
                  );
                })}
              </>
            );
          })}

          <>
            <Divider />
            <div style={{ marginTop: 20 }}>
              <Text italic>
                You can opt-out of Section 3(iii) which means that Voyage won’t use your data for training other future
                models (except finetuned models for yourself). You won’t be able to opt-in again on the website after
                you opt out. If you are interested in opting-in again after opting-out, please contact {""}
                <a href="mailto:legal@voyageai.com">legal@voyageai.com</a>.
              </Text>
            </div>
            <Flex justify="flex-end" style={{ marginTop: 20 }}>
              <Tooltip
                title={isFreeUser && !optOutStatus ? "To opt out, please add payment method on billing page." : ""}
              >
                <Switch
                  checked={!optOutStatus}
                  loading={loading || loadingOverview}
                  disabled={optOutStatus || isFreeUser}
                  checkedChildren="opted-in"
                  unCheckedChildren="opted-out"
                  onChange={onChange}
                />
              </Tooltip>
            </Flex>
          </>
        </Card>
      </Flex>
    </Row>
  );
};

export default TermsOfService;
