import { useEffect, useState } from "react";
import { Col, Row, Typography, Button, Form, Input, notification } from "antd";
import LoadingPage from "../Loading";
import { useAuth0 } from "@auth0/auth0-react";
import { getProfile, updateProfile } from "../../actions/accountActions";
import { ProfileData } from "../../types/account";
import { mobileCheck } from "../../utils/responsive";

const { Title } = Typography;

const Profile = () => {
  const isMobile = mobileCheck();

  const [loading, setLoading] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const [profileData, setProfileData] = useState<ProfileData | null>(null);

  const { user } = useAuth0();

  useEffect(() => {
    setLoading(true);
    getProfile()
      .then((res) => {
        setProfileData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  const [form] = Form.useForm();

  const onFinish = (values: ProfileData) => {
    setIsUpdating(true);
    updateProfile(values.name)
      .then(() => {
        setIsUpdating(false);
        notification.success({
          message: "Success",
          description: "User profile updated successfully.",
          duration: 5,
        });
      })
      .catch(() => {
        setIsUpdating(false);
      });
  };

  if (!user || loading) {
    return <LoadingPage />;
  }

  return (
    <Row>
      <Col span={24}>
        <Title level={5} style={{ marginTop: 0 }}>
          User Profile
        </Title>

        <Col span={isMobile ? 24 : 8}>
          <Form form={form} name="userForm" onFinish={onFinish} layout="vertical" requiredMark={false}>
            <Form.Item
              label="Name"
              name="name"
              initialValue={profileData?.name}
              rules={[{ required: true, message: "Please enter your name." }]}
              style={{ marginBottom: 10 }}
            >
              <Input placeholder="Enter your name" />
            </Form.Item>

            <Form.Item label="Email" name="email" initialValue={user.email}>
              <Input disabled />
            </Form.Item>

            <Form.Item style={{ marginTop: 20 }}>
              <Button type="primary" htmlType="submit" loading={isUpdating}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Col>
    </Row>
  );
};

export default Profile;
