import gates from "./gates";

const ngrokApiUrl = "https://voyageai.ngrok.dev/v1";

const baseApiUrl = gates["dev-gate"] ? ngrokApiUrl : "https://auth.voyageai.com/v1";

const baseUrlMap: any = {
  "www.voyageai.com": baseApiUrl,
  "www.voyageai.io": baseApiUrl,
  "dash.voyageai.io": baseApiUrl,
  localhost: process.env.REACT_APP_LOCAL_HOST ?? ngrokApiUrl,
};

const localAuth0 = {
  domain: "dev-jfj7xl5kbchebneq.us.auth0.com",
  clientId: "nQDM4dw6US7n69s197Bu9biUb7Dmd4KF",
  audience: "https://api1.voyageai.com",
};

const prodAuth0 = {
  domain: "voyageai.us.auth0.com",
  clientId: "OQdZGFum9oWrXRGi2PO38fbwU5Phfv42",
  audience: "https://apiauth0.voyageai.com",
};

export const auth0Map: any = {
  localhost: localAuth0,
  "dash.voyageai.io": localAuth0,
  "dash.voyageai.com": prodAuth0,
};

export const getBaseUrl = () => {
  return baseUrlMap[window.location.hostname] ?? baseApiUrl;
};
