import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom";
import { auth0Map } from "./utils/apiConstants";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Auth0Provider
    domain={auth0Map[window.location.hostname].domain}
    clientId={auth0Map[window.location.hostname].clientId}
    cacheLocation="localstorage"
    authorizationParams={{
      redirect_uri: `${window.location.origin}`,
      audience: auth0Map[window.location.hostname].audience,
      scope: "openid email profile read:current_user update:current_user_identities",
    }}
  >
    <Router>
      <App />
    </Router>
  </Auth0Provider>,
);
