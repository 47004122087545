import { FC } from "react";
import { Modal } from "antd";
import StripeSetup from "./StripeSetup";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  fetchPaymentMethods?: Function;
};

const AddPaymentModal: FC<Props> = ({ open, setOpen, fetchPaymentMethods }) => {
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal key="delete-new-key-modal" open={open} onCancel={handleCancel} footer={null} destroyOnClose>
      <div
        style={{
          margin: "20px 0",
        }}
      >
        <StripeSetup fetchPaymentMethods={fetchPaymentMethods} setOpen={setOpen} />
      </div>
    </Modal>
  );
};

export default AddPaymentModal;
