import axios from "../utils/axios";
const apiKeyUrl = "api-keys";

export const listApiKeys = () => axios.get(apiKeyUrl);

export const getApiKey = (id: string) => axios.get(`${apiKeyUrl}/${id}`);

export const createApiKey = (name: string) =>
  axios.post(apiKeyUrl, {
    name,
  });

export const deleteApiKey = (id: string) => axios.delete(`${apiKeyUrl}/${id}`);
