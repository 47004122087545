import { FC } from "react";
import { Space, Col, Row, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Invoice } from "../../types/billing";
import { mobileCheck } from "../../utils/responsive";
import moment from "moment";

type Props = {
  invoices: Invoice[];
  loading: boolean;
};

const InvoiceTable: FC<Props> = ({ invoices, loading }) => {
  const columns: ColumnsType<Invoice> = [
    { title: "Invoice", dataIndex: "invoice_id", key: "invoice_id", width: 350 },
    { title: "Amount", dataIndex: "amount_due", key: "amount_due" },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (created, record) => moment(created * 1000).format("MMM Do YYYY, h:mm:ss a"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Tag color={status === "paid" ? "cyan" : "red"}>{status.charAt(0).toUpperCase() + status.slice(1)}</Tag>
      ),
    },
    {
      title: "Detail",
      key: "invoice_pdf",
      dataIndex: "invoice_pdf",
      render: (invoice_pdf, record) => (
        <Space size="middle">
          <a href={invoice_pdf}>Download</a>
        </Space>
      ),
    },
  ];

  const isMobile = mobileCheck();

  const processedInvoices = invoices.map((invoice: Invoice, index: number) => {
    return {
      ...invoice,
      key: index,
    };
  });

  return (
    <>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={processedInvoices}
            pagination={false}
            size={isMobile ? "small" : "large"}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default InvoiceTable;
