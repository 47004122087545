import { FC, useState } from "react";
import { Space, Button, Col, Row, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ApiKeyType } from "../../types/apiKey";
import { DeleteOutlined } from "@ant-design/icons";
import { mobileCheck } from "../../utils/responsive";
import ApiKeyDeleteModal from "./ApiKeyDeleteModal";
import EllipsisTextWithSuffix from "../../components/navigation/EllipsisTextWithSuffix";

type Props = {
  apiKeys: ApiKeyType[];
  fetchApiKeys: Function;
  loading: boolean;
};

type DeleteButtonProps = {
  id: string;
  name: string;
  setCurrentApiKey: (key: { id: string; name: string } | null) => void;
  setOpen: (open: boolean) => void;
};

const DeleteButton: FC<DeleteButtonProps> = ({ id, setCurrentApiKey, name, setOpen }) => {
  return (
    <Button
      onClick={() => {
        setCurrentApiKey({
          id,
          name,
        });
        setOpen(true);
      }}
      icon={<DeleteOutlined />}
    />
  );
};

const ApiKeyTable: FC<Props> = ({ apiKeys, fetchApiKeys, loading }) => {
  const [currentApiKey, setCurrentApiKey] = useState<null | { id: string; name: string }>(null);
  const [open, setOpen] = useState(false);
  const columns: ColumnsType<ApiKeyType> = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Key",
      dataIndex: "api_key",
      key: "api_key",
      render: (text) => (
        <Space size="middle">
          <EllipsisTextWithSuffix copyable text={text} length={20} />
        </Space>
      ),
    },
    { title: "Created", dataIndex: "ctime", key: "ctime" },
    { title: "Last Used", dataIndex: "last_used", key: "last_used" },
    { title: "Token Usage", dataIndex: "token_usage", key: "token_usage" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Delete">
            <DeleteButton id={record.key_id} name={record.name} setCurrentApiKey={setCurrentApiKey} setOpen={setOpen} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const isMobile = mobileCheck();

  const processedApiKeys = apiKeys.map((apiKey, index) => {
    return {
      ...apiKey,
      key: index,
    };
  });
  return (
    <>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={processedApiKeys}
            pagination={false}
            size={isMobile ? "small" : "large"}
            loading={loading}
          />
        </Col>
      </Row>
      <ApiKeyDeleteModal
        fetchApiKeys={fetchApiKeys}
        name={currentApiKey?.name!}
        id={currentApiKey?.id!}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default ApiKeyTable;
