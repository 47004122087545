import React, { useState } from "react";
import { Modal, Button, Card, Flex, notification } from "antd";
import CardBrandIcon from "./CardBrandIcon";
import { PaymentMethodCardBrands, PaymentMethod } from "../../types/billing";
import { deletePaymentMethods, setDefaultPaymentMethods } from "../../actions/billingActions";

type PaymentMethodCardProps = {
  fetchPaymentMethods: Function;
  method: PaymentMethod;
  totalCards: number;
};

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({ method, fetchPaymentMethods, totalCards }) => {
  const brand = method.card_type as PaymentMethodCardBrands;
  const { id, last4, exp_month, exp_year, is_default } = method;

  const [deleting, setDeleting] = useState<boolean>(false);
  const [settingDefault, setSettingDefault] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleSetAsDefault = () => {
    setSettingDefault(true);
    setDefaultPaymentMethods(id)
      .then(async () => {
        setSettingDefault(false);
        notification.success({
          message: "Success",
          description: "Set default payment method successfully.",
          duration: 5,
        });
        await fetchPaymentMethods();
      })
      .catch(() => {
        setSettingDefault(false);
      });
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleDelete = () => {
    setDeleting(true);
    deletePaymentMethods(id)
      .then(async () => {
        setDeleting(false);
        notification.success({
          message: "Success",
          description: "Delete payment method successfully.",
          duration: 5,
        });
        await fetchPaymentMethods();
        setModalOpen(false);
      })
      .catch(() => {
        setDeleting(false);
        setModalOpen(false);
      });
  };
  return (
    <>
      <Card style={{ width: 210 }} bodyStyle={{ padding: "15px 20px" }}>
        <Flex className="payment-method-card" align="none" vertical>
          <Flex justify="space-between" align="center" style={{ marginBottom: 10 }}>
            <CardBrandIcon brand={brand} height={40} width={40} />
            <div className="card-details">
              <div className="card-number">•••• {last4}</div>
              <div className="card-expiry">
                Expires {exp_month}/{exp_year}
              </div>
            </div>
          </Flex>
          <Flex justify="space-between">
            <Button
              style={{ margin: 0, padding: 0, height: 25 }}
              type="link"
              loading={settingDefault}
              onClick={handleSetAsDefault}
              disabled={is_default}
            >
              {is_default ? "Default" : "Set as default"}
            </Button>
            <Button
              style={{ margin: 0, padding: 0, height: 25 }}
              type="link"
              loading={deleting}
              onClick={() => (totalCards === 1 ? setModalOpen(true) : handleDelete())}
              disabled={is_default && totalCards !== 1}
            >
              Delete
            </Button>
          </Flex>
        </Flex>
      </Card>

      <Modal
        key="delete-new-key-modal"
        title="Are you sure you want to delete this payment method?"
        open={modalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button danger type="primary" loading={deleting} onClick={handleDelete}>
            Continue
          </Button>,
        ]}
      >
        <div
          style={{
            margin: "20px 0",
          }}
        >
          Rate Limit will be reduced if you no longer have a payment method on file. You can still add another payment
          method later.
        </div>
      </Modal>
    </>
  );
};

export default PaymentMethodCard;
