type StorageType = "localStorage" | "sessionStorage";

const getStorage = (storageType: StorageType) => {
  if (canAccessStorage(storageType)) {
    return window[storageType];
  }

  let data: { [key: string]: string } = {};

  return {
    setItem: (id: string, val: string) => (data[id] = String(val)),
    getItem: (id: string) => (data.hasOwnProperty(id) ? data[id] : undefined),
    removeItem: (id: string) => delete data[id],
    clear: () => (data = {}),
    [Symbol.iterator]: () => data,
  };
};

const canAccessStorage = (storageType: StorageType): boolean => {
  const tmp = "__voyageai_test" + new Date().getTime();
  let isAvailable = true;

  // If we can't set anything, the storage
  // interface either isn't available, or is disabled
  // (private browsing may disable this)
  try {
    window[storageType].setItem(tmp, "1");
    window[storageType].removeItem(tmp);
  } catch (ex) {
    isAvailable = false;
    console.error(`${storageType} is inaccessible`, ex);
  }

  return isAvailable;
};

const localStorage = getStorage("localStorage") as Storage;
const sessionStorage = getStorage("sessionStorage") as Storage;

export { localStorage, sessionStorage };
