import { useState, FormEvent, useEffect, FC } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { postCreateIntent } from "../../actions/billingActions";
import { Button, notification } from "antd";
import { useLocation } from "react-router-dom";
import { routes, billingRoutes } from "../../utils/routes";

type Props = {
  setOpen: (open: boolean) => void;
  fetchPaymentMethods?: Function;
};

const StripeCheckoutForm: FC<Props> = ({ setOpen, fetchPaymentMethods }) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const isOnOverview = location.pathname === routes.billingRoutes + billingRoutes.overviewRoutes;

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const [clientSecret, setClientSecret] = useState<string>("");
  useEffect(() => {
    setLoading(true);
    postCreateIntent()
      .then(async (res) => {
        setClientSecret(res.data.client_secret);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  const handleError = (error: any) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    const options: any = {
      elements,
      clientSecret,
      confirmParams: {
        return_url: "https://dash.voyageai.com/billing/payment-methods",
      },
    };

    // if adding payment methods from overview page, we want to redirect to payment methods page
    // there is a specific logic to set the first payment method as default payment method
    if (!isOnOverview) {
      options.redirect = "if_required";
    }
    // Confirm the SetupIntent using the details collected by the Payment Element
    const { error } = await stripe.confirmSetup(options);

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the setup. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
    } else {
      setOpen(false);
      fetchPaymentMethods && (await fetchPaymentMethods());
      notification.success({
        message: "Success",
        description: "Payment method added successfully.",
        duration: 5,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button style={{ margin: "10px 0" }} type="primary" htmlType="submit" disabled={!stripe || loading}>
        Add Payment
      </Button>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default StripeCheckoutForm;
