import { useState, useCallback } from "react";
import { getOverview } from "../actions/billingActions";
import { BillingOverviewData } from "../types/billing";

export type BillingOverviewHook = {
  overviewData: BillingOverviewData | null;
  loading: boolean;
  fetchOverview: () => Promise<void>;
  isFreeUser: boolean;
};

const useBillingOverview = (): BillingOverviewHook => {
  const [overviewData, setOverviewData] = useState<BillingOverviewData | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchOverview = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getOverview();
      setOverviewData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  return {
    overviewData,
    loading,
    fetchOverview,
    isFreeUser: overviewData?.subscription_status === "inactive",
  };
};

export default useBillingOverview;
