import { useCallback, useState, useEffect } from "react";
import { Button, Col, Row, Modal, Input, Typography } from "antd";
import ApiKeyTable from "../components/apiKey/ApiKeyTable";
import { createApiKey, listApiKeys } from "../actions/apiKeyActions";
import { ApiKeyType } from "../types/apiKey";
import useBillingOverview from "../hooks/useBillingOverview";
import NoPaymentsAlert from "../components/billing/NoPaymentsAlert";

const { Text, Paragraph } = Typography;
const ApiKey = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [successCreateMsg, setSuccessCreateMsg] = useState("");
  const [keyName, setKeyName] = useState("");
  const [loading, setLoading] = useState(false);

  const [apiKeys, setApiKeys] = useState<ApiKeyType[]>([]);

  const { fetchOverview, isFreeUser } = useBillingOverview();

  const fetchApiKeys = useCallback(async () => {
    setLoading(true);
    listApiKeys()
      .then((res) => {
        setApiKeys(
          res?.data.map((apiKey: any, index: number) => {
            return {
              ...apiKey,
              key: index,
            };
          }),
        );
        setLoading(false);
      })
      .catch((e) => {
        // customized axios client has already been setup to render the
        // error notification log the error is enough
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchApiKeys();
    fetchOverview();
  }, [fetchApiKeys, fetchOverview]);

  const showModal = () => {
    setSuccessCreateMsg("");
    setKeyName("");
    setOpen(true);
  };

  const handleSubmit = () => {
    setConfirmLoading(true);
    createApiKey(!!keyName ? keyName : "API Key")
      .then((res) => {
        setSuccessCreateMsg(res.data.api_key);
        fetchApiKeys();
      })
      .catch((e) => {
        console.error("Create api key failed:", e);
      })
      .finally(() => setConfirmLoading(false));
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Row>
      <NoPaymentsAlert topMargin={false} isFreeUser={isFreeUser} />
      <Col span={24}>
        <ApiKeyTable fetchApiKeys={fetchApiKeys} apiKeys={apiKeys} loading={loading} />
      </Col>
      <Col>
        <Button key="create-api-key" style={{ marginTop: 20 }} onClick={showModal}>
          Create new API key
        </Button>
      </Col>
      <Modal
        key="create-new-key-modal"
        title="Create new API key"
        open={open}
        onCancel={handleCancel}
        footer={
          successCreateMsg
            ? [
                <Button type="primary" key="done" onClick={handleCancel}>
                  Done
                </Button>,
              ]
            : [
                <Button key="back" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button key="confirm" type="primary" loading={confirmLoading} onClick={handleSubmit}>
                  Create API key
                </Button>,
              ]
        }
      >
        {successCreateMsg ? (
          <div
            style={{
              margin: "20px 0",
            }}
          >
            <Text>API Key created successfully.</Text>
            <div
              style={{
                margin: "20px 0",
              }}
            >
              <Text key="create-key-result" copyable={{ text: successCreateMsg }}>
                {successCreateMsg}
              </Text>
            </div>
          </div>
        ) : (
          <>
            <NoPaymentsAlert topMargin={false} isFreeUser={isFreeUser} />
            <Paragraph style={{ margin: "10px 0 5px 0", fontWeight: 500 }}>Name</Paragraph>
            <Input
              key="key-name-input"
              placeholder='Default to "API Key"'
              value={keyName}
              onChange={(e) => {
                setKeyName(e.target.value);
              }}
              style={{
                marginBottom: 20,
              }}
            />
          </>
        )}
      </Modal>
    </Row>
  );
};

export default ApiKey;
