import { useCallback, useState, useEffect } from "react";
import { Col, Row, DatePicker, Flex, Select, Switch, Divider, Alert, Typography } from "antd";
import { getActivityByDate } from "../actions/activityActions";
import { Activity } from "../types/activity";
import type { DatePickerProps } from "antd";
import UsageChart from "../components/activity/UsageChart";
import dayjs from "dayjs";
import { mobileCheck } from "../utils/responsive";
import { TOTAL_USAGE_MODEL_NAME } from "../utils/chartsConstants";
import LoadingPage from "./Loading";

const { Text } = Typography;

const Usage = () => {
  const isMobile = mobileCheck();
  const currentMonth = dayjs();
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(currentMonth);
  const [selectedModel, setSelectModel] = useState("");
  const [isAccumulative, setIsAccumulative] = useState(false);
  const [activityData, setActivityData] = useState<Activity[]>([]);

  const [uniqueModelNames, setUniqueModelNames] = useState<string[]>([]);

  const fetchMonthUsage = useCallback(async () => {
    setLoading(true);
    const selectedMonthFirstDay = month.startOf("month").format("YYYY-MM-DD");
    const nextMonthFirstDay = month.add(1, "months").startOf("month").format("YYYY-MM-DD");
    getActivityByDate(selectedMonthFirstDay, nextMonthFirstDay)
      .then((res) => {
        setActivityData(res?.data);
        // get all models name user have from activity data
        setUniqueModelNames([
          TOTAL_USAGE_MODEL_NAME,
          ...Array.from(new Set((res?.data as Activity[]).map((item: Activity) => item.model_name))),
        ]);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [month]);

  useEffect(() => {
    fetchMonthUsage();
  }, [fetchMonthUsage, month]);

  const handleChangeMonth: DatePickerProps["onChange"] = (date) => {
    if (!date) return;
    setMonth(date);
  };

  const disabledDate = (current: dayjs.Dayjs) => {
    return current && current > dayjs().endOf("month");
  };

  const getOptions = () => {
    const options = uniqueModelNames.map((modelNames) => {
      return {
        value: modelNames,
        label: modelNames,
      };
    });
    return options;
  };

  return (
    <Row>
      <Alert
        message={
          <>
            <Text style={{ display: "block", marginTop: 0 }}>
              For performance reasons, the underlying usage data backing the graphs below is refreshed daily at
              approximately 7:15 PM PT. The incremental data refresh adds usage data from 5 PM PT the previous day until
              5 PM PT the current day.
            </Text>
          </>
        }
        type="success"
        style={{ marginBottom: 10 }}
      />
      <Col span={24}>
        <Flex justify="space-between" vertical={isMobile} align={isMobile ? "none" : "center"}>
          <Flex
            justify={isMobile ? "space-between" : "start"}
            align="center"
            style={{ marginBottom: isMobile ? 10 : 0, marginRight: isMobile ? 0 : 10 }}
          >
            <Switch
              style={{ marginRight: 10 }}
              checkedChildren="On"
              unCheckedChildren="Off"
              onChange={(value) => {
                setIsAccumulative(value);
              }}
            />
            Accumulative Usage
          </Flex>

          <Flex vertical={isMobile} align={isMobile ? "none" : "center"}>
            <Select
              placeholder="Models"
              style={{
                width: isMobile ? "100%" : "130px",
                marginBottom: isMobile ? 10 : 0,
                marginRight: isMobile ? 0 : 10,
              }}
              onChange={(value) => {
                setSelectModel(value);
              }}
              allowClear
              options={getOptions()}
            />

            <DatePicker
              allowClear={false}
              bordered
              picker="month"
              onChange={handleChangeMonth}
              value={month}
              disabled={loading}
              format="MMM - YYYY"
              disabledDate={disabledDate}
            />
          </Flex>
        </Flex>
      </Col>
      <Divider style={{ margin: "10px 0" }} />
      <Col span={24}>
        {loading ? (
          <LoadingPage />
        ) : (
          <UsageChart
            activityData={activityData}
            uniqueModelNames={!!uniqueModelNames.length ? uniqueModelNames : []}
            selectedMonth={month}
            selectedModel={selectedModel}
            isAccumulative={isAccumulative}
          />
        )}
      </Col>
    </Row>
  );
};

export default Usage;
