import { get, set, remove, has, clear } from "./cache";
import { localStorage, sessionStorage } from "./storage";

export type CacheStorage = {
  get: ReturnType<typeof get>;
  set: ReturnType<typeof set>;
  remove: ReturnType<typeof remove>;
  has: ReturnType<typeof has>;
  clear: ReturnType<typeof clear>;
  [Symbol.iterator]: () => Storage;
};

const localStorageCache: CacheStorage = {
  get: get(localStorage),
  set: set(localStorage),
  remove: remove(localStorage),
  has: has(localStorage),
  clear: clear(localStorage),
  [Symbol.iterator]: () => localStorage,
};

const sessionStorageCache: CacheStorage = {
  get: get(sessionStorage),
  set: set(sessionStorage),
  remove: remove(sessionStorage),
  has: has(sessionStorage),
  clear: clear(sessionStorage),
  [Symbol.iterator]: () => sessionStorage,
};

export { localStorageCache as default, sessionStorageCache };
