import { useCallback, useState, useEffect } from "react";
import { Col, Row, DatePicker, Flex, Select, Switch, Divider } from "antd";
import { getCosts } from "../../actions/billingActions";
import { CostsData } from "../../types/billing";
import type { DatePickerProps } from "antd";
import CostsChart from "../../components/billing/CostsChart";
import dayjs from "dayjs";
import { mobileCheck } from "../../utils/responsive";
import { TOTAL_COSTS_MODEL_NAME } from "../../utils/chartsConstants";
import LoadingPage from "../Loading";

const Costs = () => {
  const isMobile = mobileCheck();
  const currentMonth = dayjs();
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(currentMonth);
  const [selectedModel, setSelectModel] = useState("");
  const [isAccumulative, setIsAccumulative] = useState(false);
  const [costsData, setCostsData] = useState<CostsData[]>([]);

  const [uniqueModelNames, setUniqueModelNames] = useState<string[]>([]);

  const fetchMonthCosts = useCallback(async () => {
    setLoading(true);
    const selectedMonthFirstDay = month.startOf("month").format("YYYY-MM-DD");
    const nextMonthFirstDay = month.add(1, "months").startOf("month").format("YYYY-MM-DD");
    getCosts(selectedMonthFirstDay, nextMonthFirstDay)
      .then((res) => {
        setCostsData(res?.data);
        // get all models name user have from activity data
        setUniqueModelNames([
          TOTAL_COSTS_MODEL_NAME,
          ...Array.from(new Set((res?.data as CostsData[]).map((item: CostsData) => item.model_name))),
        ]);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [month]);

  useEffect(() => {
    fetchMonthCosts();
  }, [fetchMonthCosts, month]);

  const handleChangeMonth: DatePickerProps["onChange"] = (date) => {
    if (!date) return;
    setMonth(date);
  };

  const disabledDate = (current: dayjs.Dayjs) => {
    return current && current > dayjs().endOf("month");
  };

  const getOptions = () => {
    const options = uniqueModelNames.map((modelNames) => {
      return {
        value: modelNames,
        label: modelNames,
      };
    });
    return options;
  };

  return (
    <Row>
      <Col span={24}>
        <Flex
          justify="space-between"
          vertical={isMobile}
          align={isMobile ? "none" : "center"}
          style={{ marginTop: 10 }}
        >
          <Flex
            justify={isMobile ? "space-between" : "start"}
            align="center"
            style={{ marginBottom: isMobile ? 10 : 0, marginRight: isMobile ? 0 : 10 }}
          >
            <Switch
              style={{ marginRight: 10 }}
              checkedChildren="On"
              unCheckedChildren="Off"
              onChange={(value) => {
                setIsAccumulative(value);
              }}
            />
            Accumulative Costs
          </Flex>

          <Flex vertical={isMobile} align={isMobile ? "none" : "center"}>
            <Select
              placeholder="Models"
              style={{
                width: isMobile ? "100%" : "130px",
                marginBottom: isMobile ? 10 : 0,
                marginRight: isMobile ? 0 : 10,
              }}
              onChange={(value) => {
                setSelectModel(value);
              }}
              allowClear
              options={getOptions()}
            />

            <DatePicker
              allowClear={false}
              bordered
              picker="month"
              onChange={handleChangeMonth}
              value={month}
              disabled={loading}
              format="MMM - YYYY"
              disabledDate={disabledDate}
            />
          </Flex>
        </Flex>
      </Col>
      <Divider style={{ margin: "10px 0" }} />
      <Col span={24}>
        {loading ? (
          <LoadingPage />
        ) : (
          <CostsChart
            costsData={costsData}
            uniqueModelNames={!!uniqueModelNames.length ? uniqueModelNames : []}
            selectedMonth={month}
            selectedModel={selectedModel}
            isAccumulative={isAccumulative}
          />
        )}
      </Col>
    </Row>
  );
};

export default Costs;
