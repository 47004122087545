import { sessionStorageCache } from "../cache";

const gateOverrides = JSON.parse(sessionStorageCache.get("gateOverrides", true) ?? "{}");
// in browser, sessionStorage.setItem("gateOverrides", JSON.stringify({ "release-gate": "true" }));
// to active the gate usage
const gates = {
  "release-gate": window.location.hostname === "localhost" ? true : gateOverrides?.["release-gate"],
  "dev-gate": gateOverrides?.["dev-gate"],
};

export default gates;
