import { useEffect, useState } from "react";
import "./assets/css/App.css";
import { ConfigProvider } from "antd";
import RootRouter from "./routes/RootRoute";
import { Col, Row } from "antd";
import { sessionStorageCache } from "./cache";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingPage from "./pages/Loading";
import LoginError from "./pages/LoginError";
import useAxiosInterceptor from "./utils/useAxiosInterceptor";

const themeConfig = {
  token: {
    colorPrimary: "#00876f",
    colorInfo: "#00876f",
    borderRadius: 10,
  },
};
const App = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [errorDescription, setErrorDescription] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get("error");
    const errorDescription =
      urlParams.get("error_description") && decodeURIComponent(urlParams.get("error_description")!);

    if (error === "access_denied") {
      setErrorDescription(errorDescription ?? "");
    }
  }, []);

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !errorDescription) {
      const login = async () => {
        await loginWithRedirect();
      };
      login();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, errorDescription]);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      const setBearerToken = async () => {
        const token = await getAccessTokenSilently();
        sessionStorageCache.set("bearer", token);
      };
      setBearerToken();
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  useAxiosInterceptor();

  if ((isLoading || !isAuthenticated) && !errorDescription) {
    return (
      <ConfigProvider theme={themeConfig}>
        <LoadingPage />
      </ConfigProvider>
    );
  }

  if (errorDescription) {
    return (
      <ConfigProvider theme={themeConfig}>
        <LoginError description={errorDescription} />
      </ConfigProvider>
    );
  }

  return (
    <ConfigProvider theme={themeConfig}>
      <Row data-testid="test-example">
        <Col span={24}>
          <RootRouter />
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default App;
