import { Col, Row, Typography, Alert } from "antd";
import LoadingPage from "../Loading";
import { useEffect, useState } from "react";
import { getAccountRateLimits } from "../../actions/accountActions";
import RateLimitsTable from "../../components/account/RateLimitsTable";
import { RateLimitsData } from "../../types/account";
import NoPaymentsAlert from "../../components/billing/NoPaymentsAlert";
import useBillingOverview from "../../hooks/useBillingOverview";

const { Text, Title } = Typography;

const RateLimits = () => {
  const [loading, setLoading] = useState(false);

  const [rateLimitsData, setRateLimitsData] = useState<RateLimitsData[]>([]);

  const { fetchOverview, isFreeUser } = useBillingOverview();

  useEffect(() => {
    fetchOverview();
  }, [fetchOverview]);

  useEffect(() => {
    setLoading(true);
    getAccountRateLimits()
      .then((res) => {
        setRateLimitsData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Row>
      <Col span={24} style={{ marginBottom: 5 }}>
        <Text style={{ marginTop: 0 }}>
          For rate limit increase, please submit a request form{" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSe1YajPQZb1KGb22HgaC8PeuQjUYTcKjIa2PkC7IihOnia6dw/viewform?usp=sf_link"
            rel="noreferrer"
            target="_blank"
          >
            here
          </a>
          . The team will assess your use case and get back to you within two business days.
        </Text>
      </Col>
      <NoPaymentsAlert topMargin={false} isFreeUser={isFreeUser} />
      <Alert
        message={
          <>
            <Text style={{ display: "block", marginTop: 0 }}>
              API usage is subject to rate limits on requests per minute (RPM) and tokens per minute (TPM).
            </Text>
            <Text style={{ display: "block", marginTop: 5 }}>
              Rate limits are constraints applied to regulate the frequency and number of tokens at which a user can
              request our services within a predetermined time frame, to ensure equitable resource allocation and
              maintain system stability. Specifically, if our API server processes for a user in excess of a certain
              number of requests or tokens in the most recent minute, any additional request subsequent to this
              threshold will be denied.
            </Text>
            <Text style={{ display: "block", marginTop: 5 }}>
              For details, please see our{" "}
              <a href="https://docs.voyageai.com/docs/rate-limits" target="_blank" rel="noreferrer">
                docs on rate limits
              </a>
              .
            </Text>
          </>
        }
        type="success"
        style={{ marginBottom: 10 }}
      />
      <Col span={24}>
        <Title level={5} style={{ marginTop: 0 }}>
          Current Rate Limits
        </Title>

        <RateLimitsTable rateLimits={rateLimitsData} loading={loading} />
      </Col>
    </Row>
  );
};

export default RateLimits;
