import { FC } from "react";
import { Flex } from "antd";
import { PaymentMethod } from "../../types/billing";
import PaymentMethodCard from "./PaymentMethodCard";

type PaymentMethodsListProps = {
  fetchPaymentMethods: Function;
  paymentMethods: PaymentMethod[];
};

const PaymentMethodsList: FC<PaymentMethodsListProps> = ({ paymentMethods, fetchPaymentMethods }) => {
  return (
    <Flex style={{ marginTop: 20 }} wrap="wrap" gap="small">
      {paymentMethods.map((method) => (
        <PaymentMethodCard
          fetchPaymentMethods={fetchPaymentMethods}
          key={method.last4}
          method={method}
          totalCards={paymentMethods.length}
        />
      ))}
    </Flex>
  );
};

export default PaymentMethodsList;
