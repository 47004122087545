// This is the name to calculate all usage and display
export const TOTAL_USAGE_MODEL_NAME = "Total Usage";

export const TOTAL_COSTS_MODEL_NAME = "Total Costs";

// key to sort the result, can be totalTokens or totalRequests
export const TOKEN_SORT_KEY = "totalTokens";

export const COSTS_SORT_KEY = "totalCosts";

export const formatNumber = (number: number) => {
  return new Intl.NumberFormat("en-US").format(number);
};
