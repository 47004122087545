import { useCallback, useEffect, useState } from "react";
import { Col, Row, Typography, Button, Form, Input, notification, Select } from "antd";
import LoadingPage from "../Loading";
import { useAuth0 } from "@auth0/auth0-react";
import { getPreferences, updatePreferences } from "../../actions/billingActions";
import { PreferencesData } from "../../types/billing";
import { mobileCheck } from "../../utils/responsive";
import countries from "../../data/countries.json";
import taxIdTypes from "../../data/taxIdTypes.json";

const { Text } = Typography;

const { Option } = Select;

const Preferences = () => {
  const isMobile = mobileCheck();

  const [loading, setLoading] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const [preferencesData, setPreferencesData] = useState<PreferencesData | undefined>(undefined);

  const { user } = useAuth0();

  const fetchPreferences = useCallback(async () => {
    setLoading(true);
    getPreferences()
      .then((res) => {
        setPreferencesData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchPreferences();
  }, [fetchPreferences]);

  const [form] = Form.useForm();

  const countryOptions = countries.map((country) => ({
    code: country.alpha2,
    name: country.name,
  }));

  const onFinish = (values: PreferencesData) => {
    setIsUpdating(true);
    console.log(values);
    updatePreferences(values)
      .then(() => {
        setIsUpdating(false);
        fetchPreferences();
        notification.success({
          message: "Success",
          description: "Billing preferences updated successfully.",
          duration: 5,
        });
      })
      .catch(() => {
        setIsUpdating(false);
      });
  };

  if (!user || loading) {
    return <LoadingPage />;
  }

  return (
    <Row>
      <Col span={24}>
        <Text style={{ margin: "10px 0", display: "block" }}>
          Changes to these preferences will apply to future invoices only.
        </Text>
        <Form form={form} name="businessForm" onFinish={onFinish} layout="vertical" requiredMark={false}>
          <Form.Item label="Company Name" style={{ marginBottom: 10 }}>
            <Text style={{ display: "block", marginBottom: 5, color: "gray", fontSize: 12 }}>
              If specified, this name will appear on invoices instead of your organization name.
            </Text>
            <Col span={isMobile ? 24 : 6}>
              <Form.Item name="companyName" style={{ marginBottom: 0 }} initialValue={preferencesData?.companyName}>
                <Input />
              </Form.Item>
            </Col>
          </Form.Item>

          <Form.Item label="Purchase Order (PO) Number" style={{ marginBottom: 10 }}>
            <Text style={{ display: "block", marginBottom: 5, color: "gray", fontSize: 12 }}>
              Your PO number will be displayed on future invoices.
            </Text>
            <Col span={isMobile ? 24 : 6}>
              <Form.Item name="poNumber" style={{ marginBottom: 0 }} initialValue={preferencesData?.poNumber}>
                <Input />
              </Form.Item>
            </Col>
          </Form.Item>

          <Form.Item label="Billing Email" style={{ marginBottom: 10 }}>
            <Text style={{ display: "block", marginBottom: 5, color: "gray", fontSize: 12 }}>
              Invoices and other billing notifications will be sent here (in addition to being sent to the owners of
              your organization).
            </Text>
            <Col span={isMobile ? 24 : 6}>
              <Form.Item
                name="billingEmail"
                rules={[
                  { required: true, message: "Please enter your billing email." },
                  { type: "email", message: "Please enter a valid email address." },
                ]}
                style={{ marginBottom: 0 }}
                initialValue={preferencesData?.billingEmail}
              >
                <Input placeholder="Enter your billing email" />
              </Form.Item>
            </Col>
          </Form.Item>

          <Form.Item label="Primary Business Address" style={{ marginBottom: 10 }}>
            <Text style={{ display: "block", marginBottom: 5, color: "gray", fontSize: 12 }}>
              This is the physical address of the company purchasing Voyage AI services and is used to calculate any
              applicable sales tax.
            </Text>
            <Col span={isMobile ? 24 : 6}>
              <Form.Item
                name={["businessAddress", "country"]}
                rules={[{ required: true, message: "Please select a country." }]}
                style={{ marginBottom: 10 }}
                initialValue={preferencesData?.businessAddress?.country?.toLocaleLowerCase() ?? undefined}
              >
                <Select
                  showSearch
                  placeholder="Country"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {countryOptions.map((country) => (
                    <Option key={country.code} value={country.code}>
                      {country.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name={["businessAddress", "addressLine1"]}
                rules={[{ required: true, message: "Please enter address line 1." }]}
                style={{ marginBottom: 10 }}
                initialValue={preferencesData?.businessAddress?.addressLine1}
              >
                <Input placeholder="Address line 1" />
              </Form.Item>

              <Form.Item
                name={["businessAddress", "addressLine2"]}
                style={{ marginBottom: 10 }}
                initialValue={preferencesData?.businessAddress?.addressLine2}
              >
                <Input placeholder="Address line 2 (optional)" />
              </Form.Item>

              <Form.Item
                name={["businessAddress", "city"]}
                rules={[{ required: true, message: "Please enter the city." }]}
                style={{ marginBottom: 10 }}
                initialValue={preferencesData?.businessAddress?.city}
              >
                <Input placeholder="Enter the city" />
              </Form.Item>

              <Form.Item
                name={["businessAddress", "stateOrProvince"]}
                rules={[{ required: true, message: "Please enter the state or region." }]}
                style={{ marginBottom: 10 }}
                initialValue={preferencesData?.businessAddress?.stateOrProvince}
              >
                <Input placeholder="State, county, province or region" />
              </Form.Item>

              <Form.Item
                name={["businessAddress", "postalCode"]}
                rules={[{ required: true, message: "Please enter the postal code." }]}
                style={{ marginBottom: 0 }}
                initialValue={preferencesData?.businessAddress?.postalCode}
              >
                <Input placeholder="Postal code" />
              </Form.Item>
            </Col>
          </Form.Item>

          <Form.Item label="Business Tax ID" style={{ marginBottom: 10 }}>
            <Text style={{ display: "block", marginBottom: 5, color: "gray", fontSize: 12 }}>
              If you are a business tax registrant, please enter your business tax ID here.
            </Text>
            <Col span={isMobile ? 24 : 6}>
              <Form.Item
                name={["taxId", "type"]}
                rules={[{ required: true, message: "Please select a tax ID type." }]}
                style={{ marginBottom: 10 }}
                initialValue={preferencesData?.taxId?.type}
              >
                <Select placeholder="Tax ID type" showSearch>
                  {taxIdTypes.map((type) => (
                    <Option key={type} value={type}>
                      {type.replace("_", " ").toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name={["taxId", "id"]}
                rules={[{ required: true, message: "Please enter the tax ID." }]}
                style={{ marginBottom: 10 }}
                initialValue={preferencesData?.taxId?.id}
              >
                <Input placeholder="Tax ID" />
              </Form.Item>
            </Col>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isUpdating}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Preferences;
