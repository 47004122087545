import axios from "axios";
import { getBaseUrl } from "./apiConstants";
import { sessionStorageCache } from "../cache";
import gates from "./gates";

const bearerToken = process.env.REACT_APP_BEARER_TOKEN ?? sessionStorageCache.get("bearer");

let headers = {};

if (window.location.hostname === "localhost" || gates["dev-gate"]) {
  headers = {
    "ngrok-skip-browser-warning": "true",
  };
}

const createAxiosClient = () => {
  return axios.create({
    baseURL: getBaseUrl(),
    headers: {
      ...headers,
      authorization: `Bearer ${bearerToken}`,
    },
  });
};

const axiosClient = createAxiosClient();

export default axiosClient;
