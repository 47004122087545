import { FC } from "react";
import { Divider, Typography } from "antd";
import { useLocation } from "react-router-dom";
import { routesDataMap } from "../../utils/routes";

const TopNav: FC = () => {
  const location = useLocation();

  const getPageHeader = () => {
    return routesDataMap[location.pathname] ?? "Page Not Exist";
  };
  return (
    <>
      <Typography.Title level={3} style={{ margin: 0 }}>
        {getPageHeader()}
      </Typography.Title>
      <Divider style={{ margin: "10px 0" }} />
    </>
  );
};

export default TopNav;
